@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
html, body {
    min-width: 1280px;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root, .content-body {
    height: 100%
}
html {
    font-size: 12px;
}
@media screen and (min-width: 320px) {
    html {
        font-size: calc(12px + 4 * ((100vw - 320px) / 961));
    }
}
@media screen and (min-width: 1281px) {
    html {
        font-size: 16px;
    }
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none !important;
}

.ant-card {
    border-radius: 4px;
    box-shadow: 0rem 0.21rem 0.42rem 0 rgba(0, 0, 0, 0.16);
}
.ant-tabs-tab {
    font-size: 1.14rem;
}
.ant-tabs-tab-active,
.ant-tabs-tab:active,
.ant-tabs-tab:hover {
    color: #23202C !important;
}
.ant-tabs-ink-bar {
    background: #23202C;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item:active {
    background: #FF5858 !important;
    border-color: #FF5858 !important;
    outline: none !important;
}
.ant-pagination-item a {
    color: rgba(0, 0, 0, 0.65);
}
.ant-pagination-item-active a,
.ant-pagination-item:hover a,
.ant-pagination-item:active a {
    color: #FFFFFF !important;
}

.delete-button,
.delete-button:hover,
.delete-button:active {
    background: #FFFFFF !important;
    color: #F84B3D !important;
    border-color: #F84B3D !important;
    outline: none !important;
    font-size: 0.86rem;
}

.ant-skeleton,
.ant-alert-error {
    width: 90%;
    margin: auto;
}

.ant-modal-mask {
    background: rgba(2, 4, 41, 0.9);
}
.login-form {
  height: 100%; }
  .login-form .form-button {
    display: block;
    background: #00DB6E;
    color: #FFFFFF;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    border: none;
    outline: none; }

.form-container {
  padding: 5rem 2rem 0 2rem;
  height: 100%; }
  .form-container .logo {
    margin: auto;
    width: 13rem;
    display: block; }
  .form-container .tagline {
    text-align: center;
    margin-top: 0.7rem;
    font-weight: 300; }
  .form-container .content {
    padding-top: 3rem; }
    .form-container .content .heading {
      color: #3abc53;
      font-size: 1.43rem;
      text-align: center;
      font-weight: 400; }
    .form-container .content .heading-separator {
      border-color: #3abc53;
      margin: auto;
      width: 45%;
      margin-bottom: 1.5rem; }
    .form-container .content .ant-form-item {
      width: 80%;
      margin: auto;
      margin-bottom: 1rem;
      font-size: 1rem; }
    .form-container .content .ant-input {
      border-color: #D9D9D9; }
    .form-container .content .flex-div .ant-form-item-children {
      display: -webkit-flex;
      display: flex;
      font-size: 1rem;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
    .form-container .content .flex-div .gap {
      -webkit-flex: 1 1;
              flex: 1 1; }
  .form-container .footer {
    position: -webkit-sticky;
    position: sticky;
    top: 100%;
    padding-bottom: 30px; }
    .form-container .footer .links {
      display: -webkit-flex;
      display: flex;
      margin-left: 30%;
      width: 50%; }
      .form-container .footer .links a {
        -webkit-flex: 1 1;
                flex: 1 1; }
    .form-container .footer .copyright-text {
      margin-left: 32%;
      width: 50%; }

.side-img-col {
  background: url(/static/media/loginbg.3b950812.png);
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat; }
  .side-img-col .callout {
    max-width: 50%;
    position: absolute;
    left: 82px;
    opacity: 0; }
  .side-img-col .callout-1 {
    bottom: 210px; }
  .side-img-col .slide-up-fade-in {
    -webkit-animation: slide-up-fade-in ease 1s;
            animation: slide-up-fade-in ease 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    /*when the spec is finished*/
    opacity: 0; }

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
            transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
            transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }
  .side-img-col .callout-2 {
    bottom: 70px;
    -webkit-animation-delay: 1s;
            animation-delay: 1s; }

.forgot-password-form {
  height: 100%; }
  .forgot-password-form .sub-heading {
    font-size: 1.43rem;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem; }
  .forgot-password-form .form-button {
    display: inline-block;
    background: #1890ff;
    color: #FFFFFF;
    font-size: 1rem;
    text-align: center;
    width: 50%;
    border: none;
    outline: none; }
  .forgot-password-form .anticon.anticon-left-circle {
    font-size: 2rem; }

.signup-form-container {
  min-height: 100%;
  background: url(/static/media/setUpbackground.edc12876.png);
  background-position-y: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem;
  position: relative; }
  .signup-form-container svg.logo {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    max-width: 100%; }
  .signup-form-container .signup-card {
    width: 700px;
    max-width: 100%;
    margin: auto;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0; }
    .signup-form-container .signup-card .ant-card-body {
      padding: 2.57rem; }
      .signup-form-container .signup-card .ant-card-body .title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 0;
        color: #3a3543;
        font-weight: 500; }
      .signup-form-container .signup-card .ant-card-body .sub-title {
        text-align: center;
        font-size: 1.143rem;
        color: #000;
        font-weight: lighter; }
      .signup-form-container .signup-card .ant-card-body .signup-form {
        padding-top: 2.143rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .flex {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-align-items: flex-start;
                  align-items: flex-start; }
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .ant-form-item,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .form-button,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .disclaimer {
            width: 48%;
            margin-bottom: 0.36rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item {
          display: block;
          margin-top: 0.71rem;
          margin-bottom: 1.07rem; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input {
            border: 2px solid #cdcdcc;
            border-radius: 4px;
            min-height: 40px; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:hover,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:active,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus-within {
            border-color: #4d4d4d;
            box-shadow: 0 0 0 2px rgba(77, 77, 77, 0); }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper {
            display: block; }
            .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper span {
              color: #4d4d4d;
              font-size: 1rem;
              font-weight: lighter; }
        .signup-form-container .signup-card .ant-card-body .signup-form .has-error input {
          border-color: #ff1c1c !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .label {
          font-size: 1rem;
          font-weight: lighter;
          color: #808080; }
        .signup-form-container .signup-card .ant-card-body .signup-form .checkbox-label {
          color: #868686;
          font-size: 1rem;
          font-weight: 400;
          font-style: italic;
          display: block; }
        .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer {
          font-weight: 500; }
          .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer a {
            color: #ff806f !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .form-button {
          font-size: 1.86rem;
          background: #00db6e;
          border-color: #00db6e;
          outline: none;
          padding: 16px 10%;
          text-transform: capitalize;
          height: 5.5rem;
          max-height: 100%;
          font-weight: 600;
          border-radius: 10px; }
      .signup-form-container .signup-card .ant-card-body .bottom-link {
        color: #868686;
        font-size: 0.86rem;
        font-weight: 500;
        text-align: center;
        margin: 1rem 0 0 0; }
        .signup-form-container .signup-card .ant-card-body .bottom-link a {
          color: #ff806f !important; }
  .signup-form-container .onboarding-footer {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.signup-password-popover-content h5 {
  color: #535353;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.1rem; }

.signup-password-popover-content p {
  color: #868686;
  font-size: 1rem;
  margin-bottom: 0.1rem; }
  .signup-password-popover-content p svg {
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom; }

.onboarding-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .onboarding-footer div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 11.786rem; }
    .onboarding-footer div a {
      color: rgba(0, 0, 0, 0.45) !important;
      text-decoration: none !important;
      font-size: 1rem;
      font-weight: 400; }
  .onboarding-footer .copyright-text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.857rem;
    font-weight: 400; }

.forgot-password-form {
  height: 100%; }
  .forgot-password-form .sub-heading {
    font-size: 1.43rem;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem; }
  .forgot-password-form .form-button {
    display: inline-block;
    background: #1890ff;
    color: #FFFFFF;
    font-size: 1rem;
    text-align: center;
    width: 50%;
    border: none;
    outline: none; }
  .forgot-password-form .anticon.anticon-left-circle {
    font-size: 2rem; }

.signup-form-container {
  min-height: 100%;
  background: url(/static/media/setUpbackground.edc12876.png);
  background-position-y: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem;
  position: relative; }
  .signup-form-container svg.logo {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    max-width: 200px; }
  .signup-form-container .signup-card {
    width: 700px;
    max-width: 100%;
    margin: auto;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0; }
    .signup-form-container .signup-card .ant-card-body {
      padding: 2.57rem; }
      .signup-form-container .signup-card .ant-card-body .title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 0;
        color: #3a3543;
        font-weight: 500; }
      .signup-form-container .signup-card .ant-card-body .sub-title {
        text-align: center;
        font-size: 1.143rem;
        color: #000;
        font-weight: lighter; }
      .signup-form-container .signup-card .ant-card-body .signup-form {
        padding-top: 2.143rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .flex {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-align-items: flex-start;
                  align-items: flex-start; }
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .ant-form-item,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .form-button,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .disclaimer {
            width: 48%;
            margin-bottom: 0.36rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item {
          display: block;
          margin-top: 0.71rem;
          margin-bottom: 1.07rem; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input {
            border: 2px solid #cdcdcc;
            border-radius: 4px;
            min-height: 40px; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:hover,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:active,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus-within {
            border-color: #4d4d4d;
            box-shadow: 0 0 0 2px rgba(77, 77, 77, 0); }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper {
            display: block; }
            .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper span {
              color: #4d4d4d;
              font-size: 1rem;
              font-weight: lighter; }
        .signup-form-container .signup-card .ant-card-body .signup-form .has-error input {
          border-color: #ff1c1c !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .label {
          font-size: 1rem;
          font-weight: lighter;
          color: #808080; }
        .signup-form-container .signup-card .ant-card-body .signup-form .checkbox-label {
          color: #868686;
          font-size: 1rem;
          font-weight: 400;
          font-style: italic;
          display: block; }
        .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer {
          font-weight: 500; }
          .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer a {
            color: #ff806f !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .form-button {
          font-size: 1.86rem;
          background: #00db6e;
          border-color: #00db6e;
          outline: none;
          padding: 16px 10%;
          text-transform: capitalize;
          height: 5.5rem;
          max-height: 100%;
          font-weight: 600;
          border-radius: 10px; }
      .signup-form-container .signup-card .ant-card-body .bottom-link {
        color: #868686;
        font-size: 0.86rem;
        font-weight: 500;
        text-align: center;
        margin: 1rem 0 0 0; }
        .signup-form-container .signup-card .ant-card-body .bottom-link a {
          color: #ff806f !important; }
  .signup-form-container .onboarding-footer {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.signup-password-popover-content h5 {
  color: #535353;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.1rem; }

.signup-password-popover-content p {
  color: #868686;
  font-size: 1rem;
  margin-bottom: 0.1rem; }
  .signup-password-popover-content p svg {
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom; }

.ant-layout {
  background: #F1F0F5; }
  .ant-layout .ant-layout-has-sider {
    height: 100%; }

.ant-card {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-color: #edf2f9;
  border-radius: 0.3rem; }

.navbar {
  padding: 0 1.5rem;
  z-index: 1001;
  background: -webkit-linear-gradient(right, #0e6725, #08a765);
  background: linear-gradient(-90deg, #0e6725, #08a765);
  font-weight: 600;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .navbar .left-menu {
    -webkit-flex: 99999 1;
            flex: 99999 1;
    padding-left: 30px; }
  .navbar .right-menu {
    -webkit-flex: 0 0;
            flex: 0 0; }
    .navbar .right-menu .ant-menu-item:last-child {
      padding-right: 0; }
  .navbar .navbar-ham {
    display: none;
    color: white;
    font-size: 1.71rem; }
  .navbar .logo {
    -webkit-flex: 0 0;
            flex: 0 0; }
    .navbar .logo img {
      max-width: 3rem;
      padding-bottom: 0.25rem; }
  .navbar .ant-menu-dark {
    background: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .navbar .ant-menu-dark .ant-menu-item {
      padding: 0; }
      .navbar .ant-menu-dark .ant-menu-item a {
        font-size: 16px;
        letter-spacing: 0.3px; }
      .navbar .ant-menu-dark .ant-menu-item svg * {
        stroke: transparent;
        fill: rgba(255, 255, 255, 0.65); }
      .navbar .ant-menu-dark .ant-menu-item .user-name {
        text-transform: capitalize; }
      .navbar .ant-menu-dark .ant-menu-item .user-status-tag {
        margin-left: 0.25rem; }
      .navbar .ant-menu-dark .ant-menu-item .anticon {
        font-size: 1.5rem;
        padding-top: 1rem;
        margin-right: 0; }
        .navbar .ant-menu-dark .ant-menu-item .anticon svg {
          position: relative;
          top: 2px; }
      .navbar .ant-menu-dark .ant-menu-item .ant-avatar {
        background-color: #fc4747;
        border: 2px solid white;
        box-sizing: content-box;
        text-transform: capitalize;
        font-size: 1.57rem; }
      .navbar .ant-menu-dark .ant-menu-item .avatar-details {
        display: inline-block;
        margin: 0 0.75rem;
        font-size: 0.93rem;
        line-height: 20px;
        vertical-align: middle;
        text-transform: capitalize;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0; }
        .navbar .ant-menu-dark .ant-menu-item .avatar-details .domain-name {
          font-size: 0.78rem; }
    .navbar .ant-menu-dark .ant-menu-item {
      padding: 15px 20px; }
    .navbar .ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu-item-active,
    .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-active,
    .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active {
      background: transparent;
      color: white; }
      .navbar .ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu-item-active svg *,
      .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-active svg *,
      .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active svg * {
        fill: white; }

.navbar-user-dropdown {
  min-width: 205px;
  max-width: 300px;
  padding: 2.2px; }
  .navbar-user-dropdown p {
    margin-bottom: 0; }
  .navbar-user-dropdown .current-account-details {
    font-size: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-user-dropdown .current-account-details .account-name {
      margin-right: 0.71rem;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-flex: 999 1;
              flex: 999 1; }
    .navbar-user-dropdown .current-account-details .ant-tag {
      border-radius: 9px;
      margin-right: 0; }
  .navbar-user-dropdown .switch-account-label {
    font-size: 0.85rem;
    margin-top: 0.71rem; }
  .navbar-user-dropdown .ant-dropdown-menu-item {
    font-size: 1rem;
    padding: 15px 20px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .navbar-user-dropdown .ant-dropdown-menu-item .anticon {
      margin-left: 8px;
      display: none; }
  .navbar-user-dropdown .ant-dropdown-menu-item:hover {
    color: #00a154 !important;
    background: #cfeddf; }
    .navbar-user-dropdown .ant-dropdown-menu-item:hover .anticon {
      display: inline-block; }

@media (max-width: 768px) {
  .header .ant-menu {
    display: none; }
  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right; } }

.LetMailbox-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  padding: 1.6rem 0; }
  .LetMailbox-footer ul {
    list-style: none;
    display: inline-block;
    padding: 0; }
    .LetMailbox-footer ul li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.57rem; }
      .LetMailbox-footer ul li a {
        color: #979797 !important;
        font-weight: 500; }
      .LetMailbox-footer ul li img {
        width: 8rem; }
  .LetMailbox-footer #google_translate_element {
    display: inline-block; }

.switch-account-modal .switch-account-text .title {
  color: #5D5C5C; }

.switch-account-modal .switch-account-text Button {
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  /* padding: initial; */
  font-size: 1rem;
  border: 2px solid #979797;
  margin-bottom: 1rem; }
  .switch-account-modal .switch-account-text Button span {
    width: 90%; }
    .switch-account-modal .switch-account-text Button span .ant-tag {
      margin-left: 15px; }
  .switch-account-modal .switch-account-text Button svg {
    width: 1rem;
    height: 1rem; }

.switch-account-modal .switch-account-text Button:hover {
  font-weight: 500;
  background: #E5DDFF;
  color: #5D5C5C; }
  .switch-account-modal .switch-account-text Button:hover svg {
    width: 1.3rem;
    height: 1.3rem; }

.no-table-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 2.11rem 0; }
  .no-table-data svg {
    width: 21.51rem;
    height: 16.44rem;
    max-width: 100%;
    margin-right: 1.36rem; }
  .no-table-data .text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 24.21rem; }
    .no-table-data .text .heading {
      font-size: 1.43rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0.36rem; }
    .no-table-data .text .content {
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
      font-size: 1.143rem;
      font-weight: 400; }

.main-heading-bar {
  padding: 3rem 0 2rem 0;
  color: #000; }
  .main-heading-bar .title {
    font-size: 1.78rem;
    font-weight: 500;
    margin-bottom: 0; }
  .main-heading-bar .subtitle {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0; }
  .main-heading-bar .page-cta {
    margin-top: 6px; }
  .main-heading-bar .actions {
    text-align: right; }
    .main-heading-bar .actions .ant-btn {
      background: #FF5858;
      color: #fff;
      outline: none !important;
      border-color: #FF5858 !important;
      border-radius: 2px;
      font-size: 1rem; }

.switch-account-modal .switch-account-text .title {
  color: #5D5C5C; }

.switch-account-modal .switch-account-text Button {
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  /* padding: initial; */
  font-size: 1rem;
  border: 2px solid #979797;
  margin-bottom: 1rem; }
  .switch-account-modal .switch-account-text Button span {
    width: 90%; }
    .switch-account-modal .switch-account-text Button span .ant-tag {
      margin-left: 15px; }
  .switch-account-modal .switch-account-text Button svg {
    width: 1rem;
    height: 1rem; }

.switch-account-modal .switch-account-text Button:hover {
  font-weight: 500;
  background: #E5DDFF;
  color: #5D5C5C; }
  .switch-account-modal .switch-account-text Button:hover svg {
    width: 1.3rem;
    height: 1.3rem; }

.snapshot {
  background: #F1F0F5; }
  .snapshot .heading {
    font-size: 1.14rem;
    color: #5F5F5F; }
  .snapshot .no-data {
    margin-top: 4rem;
    padding: 0;
    background: url(/static/media/backgroundPLaceholder.d708de91.svg);
    background-position-y: 4.3rem;
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    min-height: 18.357rem;
    border-radius: 4px;
    margin-bottom: 2.88rem; }
    .snapshot .no-data .ant-card-body {
      padding: 3.12rem 4rem; }
      .snapshot .no-data .ant-card-body .heading {
        font-size: 1.786rem;
        font-weight: 500;
        color: #242425;
        margin-bottom: 0.236rem; }
      .snapshot .no-data .ant-card-body .text {
        color: rgba(0, 0, 0, 0.45);
        font-size: 1.143rem;
        width: 60%;
        margin-bottom: 1.26rem; }
      .snapshot .no-data .ant-card-body .ant-btn {
        background: #FF2F2F;
        color: #fff !important;
        text-decoration: none !important;
        font-size: 1rem;
        font-weight: 500;
        outline: none !important;
        border-color: #FF2F2F !important;
        height: 2.786rem;
        border-radius: 2px; }
      .snapshot .no-data .ant-card-body .ant-btn:hover,
      .snapshot .no-data .ant-card-body .ant-btn:active,
      .snapshot .no-data .ant-card-body .ant-btn:focus,
      .snapshot .no-data .ant-card-body .ant-btn:focus-within {
        background: rgba(255, 47, 47, 0.85) !important; }

.overall-stats .column .ant-card .antd-pro-charts-chart-card-meta {
  font-size: 0.78rem; }
  .overall-stats .column .ant-card .antd-pro-charts-chart-card-meta svg * {
    stroke: transparent;
    fill: rgba(0, 0, 0, 0.45); }

.overall-stats .column .ant-card .antd-pro-charts-chart-card-content .label {
  font-size: 1.78rem;
  color: #000;
  margin-bottom: 0.75rem; }
  .overall-stats .column .ant-card .antd-pro-charts-chart-card-content .label .anticon {
    margin-right: 0.75rem; }
    .overall-stats .column .ant-card .antd-pro-charts-chart-card-content .label .anticon svg * {
      stroke: transparent;
      fill: black; }

.overall-stats .column .ant-card .antd-pro-charts-chart-card-footer {
  font-size: 0.86rem; }
  .overall-stats .column .ant-card .antd-pro-charts-chart-card-footer .number-label {
    margin-right: 0.25rem; }
  .overall-stats .column .ant-card .antd-pro-charts-chart-card-footer .number {
    font-weight: 500;
    color: #000; }

@media (max-width: 991px) {
  .overall-stats .column {
    padding-bottom: 1rem; } }

.last-30-day-stats {
  padding-top: 3rem; }
  .last-30-day-stats .ant-card .ant-card-body {
    padding: 0rem;
    padding-top: 0.5rem; }
    .last-30-day-stats .ant-card .ant-card-body .ant-tabs-bar.ant-tabs-top-bar {
      padding-left: 3rem;
      padding-right: 3rem; }
    .last-30-day-stats .ant-card .ant-card-body .g2-tooltip-list-item::before {
      content: "\2022";
      color: red;
      font-weight: 600;
      font-size: 1.5rem;
      border-radius: 50%;
      margin-right: 0.25rem; }
    .last-30-day-stats .ant-card .ant-card-body .placeholder {
      text-align: center;
      padding: 2rem; }
      .last-30-day-stats .ant-card .ant-card-body .placeholder p {
        font-size: 2rem;
        font-weight: 700; }

.campaigns-container .main-heading-bar span.sort {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400; }

.campaigns-container .campaigns .column {
  margin-bottom: 1rem; }


.autoresponder-modal .ant-modal-body {
  max-height: 500px;
  overflow: scroll; }
  .autoresponder-modal .ant-modal-body .autoresponder-service {
    margin: 15px 0 0 0;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #e8e8e8; }
    .autoresponder-modal .ant-modal-body .autoresponder-service .autoresponder-img {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      height: 50px; }
      .autoresponder-modal .ant-modal-body .autoresponder-service .autoresponder-img img {
        max-width: 50px;
        max-height: 50px; }
  .autoresponder-modal .ant-modal-body .autoresponder-service:hover {
    background: #d1e8d1; }

.element .ant-card-body {
  padding: 0; }

.element .element-preview {
  background: #fff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  height: 210px;
  overflow: scroll; }
  .element .element-preview iframe {
    border: none;
    width: 100%;
    min-height: 200px; }

.element .preview-label {
  margin: 0;
  background: -webkit-linear-gradient(right, #0e6725, #08a765);
  background: linear-gradient(-90deg, #0e6725, #08a765);
  color: white;
  text-align: center;
  padding: 5px;
  font-weight: bold; }

.element .element-content {
  background: #f6fffb;
  padding: 24px; }
  .element .element-content .element-details {
    height: 375px; }
    .element .element-content .element-details ul {
      list-style-type: none;
      padding: 0;
      margin: 0; }
      .element .element-content .element-details ul li {
        margin: 10px 0; }
  .element .element-content .element-actions ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .element .element-content .element-actions ul li {
      -webkit-flex: 1 1;
              flex: 1 1;
      text-align: center; }

.library .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 1.5rem;
  font-size: 1.28rem;
  font-weight: 300; }

.library .ant-card-cover {
  height: 10rem;
  overflow: hidden; }

.library .selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.3); }

.library .media-meta p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem; }

.library .media-meta .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem; }

.library .media-meta .ant-checkbox-inner:after {
  width: 0.48rem;
  height: 1.07rem;
  left: 4.571429px;
  top: 9.142857px; }

.library .media-meta .ant-checkbox-wrapper {
  position: absolute;
  bottom: -1px;
  right: 0; }

.library-modal {
  width: 38rem !important; }
  .library-modal .ant-modal-content {
    padding: 3.14rem 4.14rem; }
    .library-modal .ant-modal-content .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black; }
      .library-modal .ant-modal-content .ant-modal-close .anticon {
        font-size: 20px; }
    .library-modal .ant-modal-content .ant-modal-header {
      padding: 0;
      border: 0;
      margin-bottom: 2.14rem; }
      .library-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500; }
    .library-modal .ant-modal-content .ant-modal-body {
      padding: 0; }
    .library-modal .ant-modal-content .choose-image-from-library {
      max-height: 30rem;
      overflow: scroll; }

.library .tenor-logo {
  width: 200px;
  margin: auto; }
  .library .tenor-logo svg {
    width: 100%; }
  .library .tenor-logo svg * {
    stroke: black;
    fill: black; }

.library .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 1.5rem;
  font-size: 1.28rem;
  font-weight: 300; }

.library .ant-card-cover {
  height: 10rem;
  overflow: hidden; }

.library .selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.3); }

.library .media-meta p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem; }

.library .media-meta .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem; }

.library .media-meta .ant-checkbox-inner:after {
  width: 0.48rem;
  height: 1.07rem;
  left: 4.571429px;
  top: 9.142857px; }

.library .media-meta .ant-checkbox-wrapper {
  position: absolute;
  bottom: -1px;
  right: 0; }

.library .tenor-logo {
  width: 200px;
  margin: auto; }
  .library .tenor-logo svg {
    width: 100%; }
  .library .tenor-logo svg * {
    stroke: black;
    fill: black; }

.library .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 1.5rem;
  font-size: 1.28rem;
  font-weight: 300; }

.library .ant-card-cover {
  height: 10rem;
  overflow: hidden; }

.library .selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.3); }

.library .media-meta p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem; }

.library .media-meta .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem; }

.library .media-meta .ant-checkbox-inner:after {
  width: 0.48rem;
  height: 1.07rem;
  left: 4.571429px;
  top: 9.142857px; }

.library .media-meta .ant-checkbox-wrapper {
  position: absolute;
  bottom: -1px;
  right: 0; }

.links .ant-card-body {
  padding: 24px !important; }

.links .element-details {
  height: 170px; }
  .links .element-details ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }

.links .element-actions {
  margin-top: 30px; }
  .links .element-actions ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .links .element-actions ul li {
      -webkit-flex: 1 1;
              flex: 1 1;
      text-align: center; }

.circle-ripple {
  background-color: #35ffc3;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  -webkit-animation: live_preview_pulse 0.7s linear infinite;
          animation: live_preview_pulse 0.7s linear infinite; }

@-webkit-keyframes live_preview_pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(101, 255, 120, 0.3), 0 0 0 5px rgba(101, 255, 120, 0.3), 0 0 0 10px rgba(101, 255, 120, 0.3); }
  100% {
    box-shadow: 0 0 0 5px rgba(101, 255, 120, 0.3), 0 0 0 10px rgba(101, 255, 120, 0.3), 0 0 0 15px rgba(101, 255, 120, 0); } }

@keyframes live_preview_pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(101, 255, 120, 0.3), 0 0 0 5px rgba(101, 255, 120, 0.3), 0 0 0 10px rgba(101, 255, 120, 0.3); }
  100% {
    box-shadow: 0 0 0 5px rgba(101, 255, 120, 0.3), 0 0 0 10px rgba(101, 255, 120, 0.3), 0 0 0 15px rgba(101, 255, 120, 0); } }

.preview-sidebar {
  width: 35%;
  height: 100%;
  position: fixed;
  top: 64px;
  left: 0;
  background: rgba(2, 4, 41, 0.95);
  z-index: 1000;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.45s;
  transition: -webkit-transform 0.45s;
  transition: transform 0.45s;
  transition: transform 0.45s, -webkit-transform 0.45s; }
  .preview-sidebar .live-preview {
    position: absolute;
    bottom: 0;
    width: 90%;
    display: block;
    margin: 0 auto 85px auto; }
    .preview-sidebar .live-preview iframe {
      width: 100%;
      border: none;
      background: white;
      height: 0; }
    .preview-sidebar .live-preview svg {
      margin: auto;
      display: inline-block; }

.preview-sidebar.visible {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.element-form-container {
  width: 65%;
  height: calc(100% - 64px);
  padding: 30px 0;
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 1000;
  background: white;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.45s 0.3s;
  transition: -webkit-transform 0.45s 0.3s;
  transition: transform 0.45s 0.3s;
  transition: transform 0.45s 0.3s, -webkit-transform 0.45s 0.3s; }
  .element-form-container p.form-labels {
    font-weight: 500; }
  .element-form-container .indented {
    padding-left: 30px; }
  .element-form-container .btn-green {
    margin-right: 20px; }
  .element-form-container .form-container {
    overflow: scroll;
    padding: 0 45px; }
  .element-form-container .ant-card {
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s; }
  .element-form-container .ant-radio-button-wrapper {
    text-align: center;
    height: auto;
    padding: 10px 15px 0 15px;
    vertical-align: middle; }
    .element-form-container .ant-radio-button-wrapper p {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; }
  .element-form-container .ant-radio-button-wrapper:hover {
    color: #36C626; }
  .element-form-container .ant-radio-button-wrapper-checked {
    border-color: #36C626;
    box-shadow: -1px 0 0 0 #36c625; }
  .element-form-container .template-select {
    width: 300px !important; }
    .element-form-container .template-select .ant-select-selection--single {
      display: -webkit-flex;
      display: flex;
      height: auto; }
  .element-form-container .ant-btn-danger {
    background: #f5222d;
    color: white; }
  .element-form-container .ant-card {
    height: 100%; }
    .element-form-container .ant-card .ant-card-body {
      height: 100%; }
      .element-form-container .ant-card .ant-card-body .ant-tabs {
        height: 100%; }
        .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-tab {
          border-right: 1px solid #BFBFBF; }
        .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-tab-active {
          border-right: 6px solid #020429; }
        .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content {
          height: 100%;
          padding: 0; }
          .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body > div {
            position: relative;
            height: 100%;
            overflow: scroll;
            scroll-behavior: smooth;
            padding: 0 60px; }
          .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-header {
            position: relative;
            padding-bottom: 15px;
            padding-left: 13px;
            border-bottom: 1px solid #979797;
            position: -webkit-sticky;
            position: sticky;
            padding-top: 30px;
            top: 0;
            background: white;
            z-index: 999; }
            .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-header h3 {
              font-size: 20px;
              line-height: 22px;
              font-weight: 400;
              display: inline-block;
              margin: 0;
              padding-top: 5px; }
            .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-header .right {
              position: -webkit-sticky;
              position: sticky;
              left: 100%; }
          .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body {
            height: 100%;
            padding-top: 0; }
            .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body .ant-form-item-control {
              margin: 16px 0; }
              .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body .ant-form-item-control p.form-labels {
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 22px;
                color: #464646; }
                .element-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body .ant-form-item-control p.form-labels .switch-green {
                  margin-left: 16px;
                  margin-top: -3px; }

.element-form-container.visible {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: -webkit-transform 0.45s;
  transition: -webkit-transform 0.45s;
  transition: transform 0.45s;
  transition: transform 0.45s, -webkit-transform 0.45s;
  padding: 30px 0; }
  .element-form-container.visible .ant-card {
    -webkit-transition: opacity 0.3s 0.35s;
    transition: opacity 0.3s 0.35s;
    opacity: 1; }

@font-face {
  font-family: 'font1.ttf';
  src: url(/static/media/font1.b44be355.ttf) format("truetype"); }

@font-face {
  font-family: 'font2.ttf';
  src: url(/static/media/font2.668ca954.ttf) format("truetype"); }

@font-face {
  font-family: 'font3.ttf';
  src: url(/static/media/font3.1e222ed2.ttf) format("truetype"); }

@font-face {
  font-family: 'font4.ttf';
  src: url(/static/media/font4.73b238bc.ttf) format("truetype"); }

@font-face {
  font-family: 'font5.ttf';
  src: url(/static/media/font5.5ec5cb50.ttf) format("truetype"); }

@font-face {
  font-family: 'font6.ttf';
  src: url(/static/media/font6.62b4dc52.ttf) format("truetype"); }

@font-face {
  font-family: 'font7.ttf';
  src: url(/static/media/font7.7bc70448.ttf) format("truetype"); }

@font-face {
  font-family: 'font8.ttf';
  src: url(/static/media/font8.be7d38c9.ttf) format("truetype"); }

@font-face {
  font-family: 'Roboto-Regular.ttf';
  src: url(/static/media/Roboto-Regular.df7b648c.ttf) format("truetype"); }

@font-face {
  font-family: 'Roboto-Medium.ttf';
  src: url(/static/media/Roboto-Medium.d0884059.ttf) format("truetype"); }

@font-face {
  font-family: 'Arimo-Bold.ttf';
  src: url(/static/media/Arimo-Bold.927b7dca.ttf) format("truetype"); }

@font-face {
  font-family: 'Arimo-Regular.ttf';
  src: url(/static/media/Arimo-Regular.7ac02a54.ttf) format("truetype"); }

@font-face {
  font-family: 'OpenSans-Bold.ttf';
  src: url(/static/media/OpenSans-Bold.f5331cb6.ttf) format("truetype"); }

@font-face {
  font-family: 'OpenSans-Regular.ttf';
  src: url(/static/media/OpenSans-Regular.d7d5d458.ttf) format("truetype"); }

@font-face {
  font-family: 'Quicksand-Medium.ttf';
  src: url(/static/media/Quicksand-Medium.0c642332.ttf) format("truetype"); }

@font-face {
  font-family: 'Quicksand-Regular.ttf';
  src: url(/static/media/Quicksand-Regular.f87b9b4f.ttf) format("truetype"); }

@font-face {
  font-family: 'Raleway-Medium.ttf';
  src: url(/static/media/Raleway-Medium.430a0518.ttf) format("truetype"); }

@font-face {
  font-family: 'Raleway-Regular.ttf';
  src: url(/static/media/Raleway-Regular.580d0778.ttf) format("truetype"); }

.btn-red {
  background: #FF2F2F;
  border-color: #FF2F2F;
  max-height: 36px;
  width: 115px;
  color: white;
  font-size: 14px; }

.btn-green {
  color: white;
  background: #36C626;
  border-color: #36C626;
  border-radius: 4px;
  min-width: 169px; }

.btn-green:hover {
  color: white;
  background: #60d155;
  border-color: #60d155;
  border-radius: 4px;
  min-width: 169px; }

.new-campaign input {
  height: 36px;
  font-size: 14px;
  line-height: 22px; }

.new-campaign hr {
  border-top: 1px solid #BFBFBF;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.new-campaign .ant-card {
  background: transparent; }

.new-campaign .has-error p.form-labels {
  color: #FF2F2F !important; }

.new-campaign .has-error .ant-input {
  box-shadow: 0 0 3px #f5222d; }

.new-campaign .react-draggable {
  overflow: hidden; }

.new-campaign .ql-editor {
  background: white;
  min-height: 300px; }

.new-campaign .switch-green {
  background: #D7DBD7; }

.new-campaign .switch-green.ant-switch-checked {
  background: #36C626; }

.new-campaign .switch-green.ant-click-animating-node {
  background: 0 solid #36C626; }

.new-campaign .element-container {
  border: 1px solid #bfbfbf;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 18px;
  font-weight: bold;
  border-radius: 7px;
  text-align: center;
  margin: 25px 0;
  padding: 20px;
  cursor: pointer;
  background: white; }
  .new-campaign .element-container img {
    max-width: 100%;
    display: block;
    max-height: 150px;
    margin: 0 auto 10px auto; }

.new-campaign .element-container:hover {
  border: 1px solid #00a154 !important;
  background: #cfeddf; }

.new-campaign .poll-option {
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #36C626;
  border-radius: 7px;
  position: relative; }
  .new-campaign .poll-option .poll-option-remove {
    position: absolute;
    z-index: 9;
    top: 15px;
    right: 15px; }

.new-campaign .ant-form-item-control {
  margin: 16px 0; }
  .new-campaign .ant-form-item-control p.form-labels {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: #464646; }
    .new-campaign .ant-form-item-control p.form-labels .switch-green {
      margin-left: 16px;
      margin-top: -3px; }

.new-campaign .ant-form-item-control.disabled p.form-labels {
  color: #C7C7C7; }

.new-campaign .ant-card-body {
  padding: 6px; }

.new-campaign .ant-tabs {
  overflow: unset; }

.new-campaign .ant-tabs-bar {
  width: 20%;
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  border-right: 1px solid #BFBFBF; }
  .new-campaign .ant-tabs-bar .ant-card-body {
    padding-right: 0; }
  .new-campaign .ant-tabs-bar .ant-tabs-nav-container {
    min-height: calc(0.8 * (100vh - 166px)); }
    .new-campaign .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-tab {
      text-align: left;
      padding: 22px 30px;
      color: rgba(29, 29, 29, 0.65);
      font-size: 16px;
      margin-bottom: 0; }
    .new-campaign .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-tab-active {
      font-weight: 600;
      color: #00a154 !important;
      border-right: 6px solid #00a154;
      background: #cfeddf; }
    .new-campaign .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-ink-bar {
      width: 6px; }

.new-campaign .ant-tabs-left-content {
  border-left: 1px solid #BFBFBF; }

.new-campaign .ant-tabs-tab {
  border-right: 1px solid #BFBFBF; }

.new-campaign .ant-tabs-content {
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 0; }
  .new-campaign .ant-tabs-content .tab-body {
    padding: 30px 60px; }
  .new-campaign .ant-tabs-content .ant-tabs-tabpane {
    min-width: 678px; }

.upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.profile-settings .profile-form {
  color: #000; }
  .profile-settings .profile-form .column {
    padding: 1.5rem;
    padding-bottom: 0; }
  .profile-settings .profile-form .form-labels {
    margin: 0;
    font-size: 1.14rem;
    color: #151515; }
  .profile-settings .profile-form .button-container {
    text-align: right; }
    .profile-settings .profile-form .button-container .ant-btn-primary {
      background: #92c15f;
      border-color: #92c15f; }
    .profile-settings .profile-form .button-container .ant-btn-primary:hover, .profile-settings .profile-form .button-container .ant-btn-primary:focus {
      background: #69bd42;
      border-color: #69bd42; }
  @media (max-width: 576px) {
    .profile-settings .profile-form .button-container {
      text-align: center; } }
  .profile-settings .profile-form .ant-divider-horizontal {
    margin: 1rem 0; }

.manage-accounts .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
  font-size: 1.28rem;
  font-weight: 300; }

.manage-accounts .ant-card {
  border-radius: 2px;
  padding: 0.57rem; }
  .manage-accounts .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 0.5rem; }
    .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn span {
        display: none; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .text {
        display: inline-block;
        height: 1.14rem;
        margin: 0;
        width: 0;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover {
      background: #23202C;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover {
      background: #F84B3D;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn {
      border: 1px solid #FF8200;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn svg * {
        stroke: #FF8200;
        fill: #FF8200; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover {
      background: #FF8200;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover svg * {
        fill: white; }

.manage-accounts .ant-table th:first-of-type, .manage-accounts .ant-table td:first-of-type {
  padding-left: 3rem; }

.manage-accounts .ant-table th:last-of-type, .manage-accounts .ant-table td:last-of-type {
  padding-right: 3rem; }

.edit-views-modal .ant-modal-header {
  padding: 0;
  border-bottom: 0; }
  .edit-views-modal .ant-modal-header .ant-modal-title {
    text-align: center;
    font-size: 1.42rem;
    padding-top: 3.5rem; }

.edit-views-modal .ant-modal-body {
  padding: 1.71rem 5.3rem;
  text-align: center;
  font-size: 1.14rem; }
  .edit-views-modal .ant-modal-body .edit-view-modal-save-btn {
    margin-top: 1rem;
    background: #36C626;
    border-color: #36C626;
    color: white;
    border-radius: 3px; }

.manage-accounts .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
  font-size: 1.28rem;
  font-weight: 300; }

.manage-accounts .ant-card {
  border-radius: 2px;
  padding: 0.57rem; }
  .manage-accounts .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 0.5rem; }
    .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn span {
        display: none; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .text {
        display: inline-block;
        height: 1.14rem;
        margin: 0;
        width: 0;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover {
      background: #23202C;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover {
      background: #F84B3D;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn {
      border: 1px solid #FF8200;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn svg * {
        stroke: #FF8200;
        fill: #FF8200; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover {
      background: #FF8200;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover svg * {
        fill: white; }

.manage-accounts .ant-table th:first-of-type, .manage-accounts .ant-table td:first-of-type {
  padding-left: 3rem; }

.manage-accounts .ant-table th:last-of-type, .manage-accounts .ant-table td:last-of-type {
  padding-right: 3rem; }

.edit-views-modal .ant-modal-header {
  padding: 0;
  border-bottom: 0; }
  .edit-views-modal .ant-modal-header .ant-modal-title {
    text-align: center;
    font-size: 1.42rem;
    padding-top: 3.5rem; }

.edit-views-modal .ant-modal-body {
  padding: 1.71rem 5.3rem;
  text-align: center;
  font-size: 1.14rem; }
  .edit-views-modal .ant-modal-body .edit-view-modal-save-btn {
    margin-top: 1rem;
    background: #36C626;
    border-color: #36C626;
    color: white;
    border-radius: 3px; }

.manage-accounts .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
  font-size: 1.28rem;
  font-weight: 300; }

.manage-accounts .ant-card {
  border-radius: 2px;
  padding: 0.57rem; }
  .manage-accounts .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 0.5rem; }
    .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn span {
        display: none; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .text {
        display: inline-block;
        height: 1.14rem;
        margin: 0;
        width: 0;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover {
      background: #23202C;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover {
      background: #F84B3D;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn {
      border: 1px solid #FF8200;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn svg * {
        stroke: #FF8200;
        fill: #FF8200; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover {
      background: #FF8200;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover svg * {
        fill: white; }

.manage-accounts .ant-table th:first-of-type, .manage-accounts .ant-table td:first-of-type {
  padding-left: 3rem; }

.manage-accounts .ant-table th:last-of-type, .manage-accounts .ant-table td:last-of-type {
  padding-right: 3rem; }

.edit-views-modal .ant-modal-header {
  padding: 0;
  border-bottom: 0; }
  .edit-views-modal .ant-modal-header .ant-modal-title {
    text-align: center;
    font-size: 1.42rem;
    padding-top: 3.5rem; }

.edit-views-modal .ant-modal-body {
  padding: 1.71rem 5.3rem;
  text-align: center;
  font-size: 1.14rem; }
  .edit-views-modal .ant-modal-body .edit-view-modal-save-btn {
    margin-top: 1rem;
    background: #36C626;
    border-color: #36C626;
    color: white;
    border-radius: 3px; }

.settings .ant-tabs {
  background: white;
  border: 1px solid #DEDEDE;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem 1rem; }
  .settings .ant-tabs .ant-tabs-tab {
    font-size: 1.14rem;
    color: #1D1D1D !important;
    font-weight: 300;
    text-align: left;
    padding-right: 5rem;
    padding-bottom: 1.25rem; }
  .settings .ant-tabs .ant-tabs-tab-active {
    color: #100E1A !important;
    font-weight: 500; }
  .settings .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    width: 5px;
    background: #100E1A; }

.account-settings {
  padding: 1.5rem 3rem; }
  .account-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .account-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .account-settings .heading .ant-btn {
      background: #36C626;
      border-radius: 4px;
      border-color: #36C626 !important;
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      min-height: 2.5rem; }
  .account-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .account-settings .input-container {
    margin-bottom: 1rem; }
    .account-settings .input-container .label {
      color: #151515;
      font-size: 1.14rem;
      margin-bottom: 0.5rem;
      font-weight: 400; }
    .account-settings .input-container .ant-input {
      border: 1px solid #979797;
      border-radius: 4px;
      font-size: 1rem;
      padding: 1rem; }
    .account-settings .input-container .ant-input-disabled {
      background: #F1F0F5; }
    .account-settings .input-container .ant-select-selection:hover {
      border: 1px solid #979797; }
    .account-settings .input-container .switch-text {
      font-size: 1rem;
      font-weight: 400;
      color: #8C8C8C; }
      .account-settings .input-container .switch-text .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem; }
      .account-settings .input-container .switch-text .ant-switch-checked {
        background: #36C626; }
  .account-settings .col-1 .input-container {
    margin-bottom: 2rem; }
  .account-settings .domain_name_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem; }
    .account-settings .domain_name_container .ant-input {
      -webkit-flex: 0.9 1;
              flex: 0.9 1; }
    .account-settings .domain_name_container .anticon {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      cursor: pointer; }
      .account-settings .domain_name_container .anticon svg {
        width: 1.8rem;
        height: 1.8rem; }
  .account-settings .add-domain-btn {
    background: #FF5858;
    border-color: #FF5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    height: 2.5rem; }
    .account-settings .add-domain-btn .anticon + span {
      margin-left: 0.25rem; }
  .account-settings .add-domain-btn:hover,
  .account-settings .add-domain-btn:active,
  .account-settings .add-domain-btn:focus,
  .account-settings .add-domain-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.user-settings {
  padding: 1.5rem 3rem; }
  .user-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .user-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .user-settings .heading .add-user-btn {
      background: #FF5858;
      border-color: #FF5858;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 3px;
      height: 2.5rem; }
      .user-settings .heading .add-user-btn .anticon + span {
        margin-left: 0.25rem; }
    .user-settings .heading .add-user-btn:hover,
    .user-settings .heading .add-user-btn:active,
    .user-settings .heading .add-user-btn:focus,
    .user-settings .heading .add-user-btn:focus-within {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }
  .user-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .user-settings .disclaimer {
    font-size: 1.14rem;
    color: #979797; }
  .user-settings .ant-table {
    margin-top: 1.5rem; }
    .user-settings .ant-table .user-name {
      text-transform: capitalize;
      margin-bottom: 0; }
    .user-settings .ant-table .role-tag {
      border-radius: 9px;
      font-size: 0.86rem;
      font-weight: 500;
      padding: 0 1rem; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn {
      height: 38px;
      width: 38px;
      font-size: 1rem;
      padding: 8px;
      margin-right: 0.714rem;
      font-weight: 500; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn:hover svg * {
      fill: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover {
      background: #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn:hover {
      background: #F84B3D; }

.user-modal .ant-modal-content {
  border-radius: 3px;
  border: 1px solid #707070;
  box-shadow: none;
  padding: 2rem; }
  .user-modal .ant-modal-content .ant-modal-close {
    display: none; }
  .user-modal .ant-modal-content .ant-modal-header {
    border: none; }
    .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom {
      font-size: 1.78rem;
      color: #020429;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg {
        width: 1.71rem;
        height: 1.71rem;
        cursor: pointer; }
        .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg * {
          color: rgba(0, 0, 0, 0.65); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .label {
    font-size: 1.14rem;
    color: #464646;
    font-weight: 400;
    margin-bottom: 0.5rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select {
    width: 100%; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select-dropdown-menu-item {
    border-bottom: 2px solid; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button {
    width: 100%;
    background: #36C626;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    height: 2.86rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:hover,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:active,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus-within {
    background: rgba(54, 198, 38, 0.9); }

.option-span {
  margin-bottom: 0; }

.role-permissions-data .title {
  font-size: 0.71rem;
  color: #595959;
  font-weight: 500;
  margin-bottom: 0.5rem; }

.role-permissions-data p {
  font-size: 0.71rem;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .role-permissions-data p svg {
    width: 0.93rem;
    height: 0.93rem;
    margin-right: 0.5rem; }

.whitelabel-instructions p {
  margin-bottom: 0; }

.user-settings {
  padding: 1.5rem 3rem; }
  .user-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .user-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .user-settings .heading .add-user-btn {
      background: #FF5858;
      border-color: #FF5858;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 3px;
      height: 2.5rem; }
      .user-settings .heading .add-user-btn .anticon + span {
        margin-left: 0.25rem; }
    .user-settings .heading .add-user-btn:hover,
    .user-settings .heading .add-user-btn:active,
    .user-settings .heading .add-user-btn:focus,
    .user-settings .heading .add-user-btn:focus-within {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }
  .user-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .user-settings .disclaimer {
    font-size: 1.14rem;
    color: #979797; }
  .user-settings .ant-table {
    margin-top: 1.5rem; }
    .user-settings .ant-table .user-name {
      text-transform: capitalize;
      margin-bottom: 0; }
    .user-settings .ant-table .role-tag {
      border-radius: 9px;
      font-size: 0.86rem;
      font-weight: 500;
      padding: 0 1rem; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn {
      height: 38px;
      width: 38px;
      font-size: 1rem;
      padding: 8px;
      margin-right: 0.714rem;
      font-weight: 500; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn:hover svg * {
      fill: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover {
      background: #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn:hover {
      background: #F84B3D; }

.user-modal .ant-modal-content {
  border-radius: 3px;
  border: 1px solid #707070;
  box-shadow: none;
  padding: 2rem; }
  .user-modal .ant-modal-content .ant-modal-close {
    display: none; }
  .user-modal .ant-modal-content .ant-modal-header {
    border: none; }
    .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom {
      font-size: 1.78rem;
      color: #020429;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg {
        width: 1.71rem;
        height: 1.71rem;
        cursor: pointer; }
        .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg * {
          color: rgba(0, 0, 0, 0.65); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .label {
    font-size: 1.14rem;
    color: #464646;
    font-weight: 400;
    margin-bottom: 0.5rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select {
    width: 100%; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select-dropdown-menu-item {
    border-bottom: 2px solid; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button {
    width: 100%;
    background: #36C626;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    height: 2.86rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:hover,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:active,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus-within {
    background: rgba(54, 198, 38, 0.9); }

.option-span {
  margin-bottom: 0; }

.role-permissions-data .title {
  font-size: 0.71rem;
  color: #595959;
  font-weight: 500;
  margin-bottom: 0.5rem; }

.role-permissions-data p {
  font-size: 0.71rem;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .role-permissions-data p svg {
    width: 0.93rem;
    height: 0.93rem;
    margin-right: 0.5rem; }

.bonus-page table {
  width: 100%; }
  .bonus-page table td {
    vertical-align: top;
    padding: 8px 0; }
  .bonus-page table th:nth-child(2), .bonus-page table td:nth-child(2) {
    width: calc(100% - 400px);
    padding-left: 15px;
    padding-right: 15px; }

.activation-codes .ant-input {
  line-height: 32px; }

.onboarding-container {
  background: url(/static/media/Onboarding.41fd81a9.svg);
  background-size: cover;
  background-position-y: bottom;
  background-attachment: fixed; }
  .onboarding-container .onboarding {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 4.143rem; }
    .onboarding-container .onboarding .logo {
      width: 14.57rem;
      height: 3.71rem;
      margin-bottom: 1.643rem; }
    .onboarding-container .onboarding .progress-bar {
      position: relative;
      width: 22.21rem;
      height: 1.43rem;
      border-radius: 10px;
      background: #EDEDED;
      margin-bottom: 4.143rem; }
    .onboarding-container .onboarding .progress-bar::after {
      content: '';
      position: absolute;
      left: 0;
      background: #36C626;
      border-radius: 10px;
      width: 8rem;
      height: 1.43rem;
      z-index: 9; }
    .onboarding-container .onboarding .title {
      color: #020429;
      font-size: 1.43rem;
      font-weight: 500;
      margin-bottom: 0.43rem; }
    .onboarding-container .onboarding .sub-title {
      font-size: 1.286rem;
      color: #8C8C8C;
      font-weight: 400;
      margin-bottom: 2rem; }

.ant-modal.onboarding-modal .ant-modal-content {
  border-radius: 3px; }
  .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body {
    padding: 3.36rem 3.786rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .heading {
      font-size: 1.714rem;
      font-weight: 500;
      margin-bottom: 1.93rem;
      color: rgba(0, 0, 0, 0.85); }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn {
      background: #36C626;
      color: #fff;
      outline: none;
      font-size: 1rem;
      font-weight: 600;
      width: 20.71rem;
      height: 2.86rem;
      outline: none !important; }
      .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn .anticon svg {
        width: 0.968rem;
        height: 0.82rem;
        margin-bottom: 0;
        vertical-align: text-top; }
        .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn .anticon svg * {
          fill: #fff; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:hover,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:active,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:focus,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:focus-within {
      border-color: #36C626; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body svg {
  width: 10rem;
  height: 10rem;
  -webkit-margin-after: 1.71rem;
          margin-block-end: 1.71rem; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body .heading {
  color: #020429;
  font-size: 1.786rem;
  font-weight: 600; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para {
  font-size: 1.14rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #8C8C8C; }
  .ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para img {
    width: 1.286rem;
    vertical-align: text-bottom; }
  .ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para .name {
    text-transform: capitalize; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para-3 {
  color: #464646; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body .below-button {
  font-size: 0.86rem;
  color: #8C8C8C;
  font-weight: 400;
  margin-top: 0.286rem; }

.ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body,
.ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body {
  padding: 3.214rem 3.357rem; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body svg,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body svg {
    width: 5.14rem;
    height: 5.14rem;
    margin-bottom: 2.21rem; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .disclaimer,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .disclaimer {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    margin-bottom: 0; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .below-disclaimer,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .below-disclaimer {
    margin-top: 2.07rem; }

.onboarding-form .ant-form-item {
  margin-bottom: 1.93rem; }
  .onboarding-form .ant-form-item .input-label {
    font-size: 1.143rem;
    margin-bottom: 0.5rem;
    color: #151515;
    font-weight: 400; }
  .onboarding-form .ant-form-item .ant-input {
    border: 1px solid #979797;
    width: 19.138rem;
    height: 2.286rem;
    font-size: 1rem;
    font-weight: 400; }
  .onboarding-form .ant-form-item .ant-btn {
    width: 19.143rem;
    height: 2.857rem;
    border: none !important;
    background: #36C626;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 2rem; }
    .onboarding-form .ant-form-item .ant-btn svg {
      width: 0.97rem;
      height: 0.82rem;
      vertical-align: text-top; }
      .onboarding-form .ant-form-item .ant-btn svg * {
        fill: #fff; }

.onboarding-form .ant-form-item:last-of-type {
  margin-bottom: 0; }

.onboarding-tooltip .ant-tooltip-arrow {
  top: 50%;
  margin-top: -10px;
  left: 0px;
  border-width: 10px 8.16px 10px 0;
  border-right-color: rgba(0, 0, 0, 0.75); }

.onboarding-tooltip .ant-tooltip-inner {
  padding: 0.643rem 1.286rem;
  font-size: 0.857rem;
  background: #3C3C3C;
  color: #FFFFFF;
  width: 15.642rem; }

.user-profile-form {
  width: 500px; }
  .user-profile-form .ant-form-item {
    margin-bottom: 1rem; }
    .user-profile-form .ant-form-item .label {
      font-size: 1rem;
      color: #595959; }
    .user-profile-form .ant-form-item .ant-input, .user-profile-form .ant-form-item .ant-select-selection {
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      height: 2.5rem;
      font-size: 1rem; }
    .user-profile-form .ant-form-item .ant-select-selection__rendered {
      height: 2.5rem;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .user-profile-form .ant-form-item .ant-btn-default {
      border-radius: 3;
      background: #36C626;
      color: #fff;
      height: 2.5rem;
      font-size: 1rem;
      font-weight: 600;
      padding: 0 1.71rem;
      margin: 0;
      border: none !important;
      outline: none !important; }
      .user-profile-form .ant-form-item .ant-btn-default svg {
        width: 1rem;
        height: 1rem; }
        .user-profile-form .ant-form-item .ant-btn-default svg * {
          fill: #fff;
          stroke-width: 0; }

.campaign-report .ant-card {
  background: #fff;
  border-radius: 2px;
  border: none;
  box-shadow: none;
  margin-bottom: 40px; }
  .campaign-report .ant-card .ant-card-body {
    padding: 17px 0; }
    .campaign-report .ant-card .ant-card-body .ant-tabs-bar {
      padding: 0 32px; }

.campaign-report .hourwise-stats-card .ant-card-body {
  padding-bottom: 0; }

.campaign-report .pie-chart-cards {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .campaign-report .pie-chart-cards .ant-card .ant-card-head {
    border-bottom: 0; }
    .campaign-report .pie-chart-cards .ant-card .ant-card-head .ant-card-head-title {
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 1.21rem;
      padding: 1.43rem 0; }
  .campaign-report .pie-chart-cards .ant-card .ant-card-body {
    padding-top: 0; }
  .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll {
    text-align: center; }
    .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav {
      border: 1px solid #D9D9D9;
      margin-bottom: 1.19rem;
      border-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab {
        padding: 0.48rem 1.71rem;
        margin: 0;
        font-size: 1.07rem;
        color: #595959; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
        color: #fff !important;
        background: #1890FF;
        border-color: #1890FF;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab:last-of-type.ant-tabs-tab-active {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-ink-bar {
        display: none !important; }

.hourwise-stats-tabs .g2-tooltip-list-item::before {
  content: "\2022";
  color: #2D6F26;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  margin-right: 0.25rem; }

.daywise-stats-tabs .g2-tooltip-list-item::before {
  content: "\2022";
  color: #8E3030;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  margin-right: 0.25rem; }


.pieslice-container {
  padding-top: 2.092rem; }
  .pieslice-container .table {
    padding: 0 3rem;
    margin-top: 3rem;
    overflow: scroll;
    max-height: 20rem;
    margin-bottom: 3.17rem; }
    .pieslice-container .table .heading, .pieslice-container .table .data {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .pieslice-container .table .heading {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background: white; }
    .pieslice-container .table .heading p,
    .pieslice-container .table .data p {
      font-size: 1.071rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      text-transform: capitalize;
      margin-bottom: 1.68rem; }
    .pieslice-container .table .data p {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 1.52rem; }



.countrywise-stats-container .ant-tabs-tabpane {
  padding: 0 0.786rem;
  margin-bottom: 2.21rem; }

.countrywise-stats-container .table {
  padding: 0 0.786rem; }
  .countrywise-stats-container .table .content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #E8E8E8; }
    .countrywise-stats-container .table .content p {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      padding-left: 50px;
      font-size: 1.07rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 1.73rem;
      padding-top: 1.486rem; }
    .countrywise-stats-container .table .content p:last-of-type {
      padding-left: 0px;
      padding-right: 50px;
      text-align: right; }
    .countrywise-stats-container .table .content p.middle {
      text-align: center; }
  .countrywise-stats-container .table .content:first-of-type {
    background: #FAFAFA;
    padding-top: 1rem;
    border-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .countrywise-stats-container .table .content:first-of-type p {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1.36rem;
      padding-top: 0; }
  .countrywise-stats-container .table .content:last-of-type {
    border-bottom: 0; }

.campaign-report .ant-card {
  background: #fff;
  border-radius: 2px;
  border: none;
  box-shadow: none;
  margin-bottom: 40px; }
  .campaign-report .ant-card .ant-card-body {
    padding: 17px 0; }
    .campaign-report .ant-card .ant-card-body .ant-tabs-bar {
      padding: 0 32px; }

.campaign-report .hourwise-stats-card .ant-card-body {
  padding-bottom: 0; }

.campaign-report .pie-chart-cards {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .campaign-report .pie-chart-cards .ant-card .ant-card-head {
    border-bottom: 0; }
    .campaign-report .pie-chart-cards .ant-card .ant-card-head .ant-card-head-title {
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 1.21rem;
      padding: 1.43rem 0; }
  .campaign-report .pie-chart-cards .ant-card .ant-card-body {
    padding-top: 0; }
  .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll {
    text-align: center; }
    .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav {
      border: 1px solid #D9D9D9;
      margin-bottom: 1.19rem;
      border-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab {
        padding: 0.48rem 1.71rem;
        margin: 0;
        font-size: 1.07rem;
        color: #595959; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
        color: #fff !important;
        background: #1890FF;
        border-color: #1890FF;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab:last-of-type.ant-tabs-tab-active {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-ink-bar {
        display: none !important; }

.hourwise-stats-tabs .g2-tooltip-list-item::before {
  content: "\2022";
  color: #2D6F26;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  margin-right: 0.25rem; }

.daywise-stats-tabs .g2-tooltip-list-item::before {
  content: "\2022";
  color: #8E3030;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  margin-right: 0.25rem; }




.countrywise-stats-container .ant-tabs-tabpane {
  padding: 0 0.786rem;
  margin-bottom: 2.21rem; }

.countrywise-stats-container .table {
  padding: 0 0.786rem; }
  .countrywise-stats-container .table .content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #E8E8E8; }
    .countrywise-stats-container .table .content p {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      padding-left: 50px;
      font-size: 1.07rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 1.73rem;
      padding-top: 1.486rem; }
    .countrywise-stats-container .table .content p:last-of-type {
      padding-left: 0px;
      padding-right: 50px;
      text-align: right; }
    .countrywise-stats-container .table .content p.middle {
      text-align: center; }
  .countrywise-stats-container .table .content:first-of-type {
    background: #FAFAFA;
    padding-top: 1rem;
    border-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .countrywise-stats-container .table .content:first-of-type p {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1.36rem;
      padding-top: 0; }
  .countrywise-stats-container .table .content:last-of-type {
    border-bottom: 0; }

