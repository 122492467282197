@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@font-face {
  font-family: 'font1.ttf';
  src: url('../../../assets/fonts/font1.ttf') format('truetype');
}
@font-face {
  font-family: 'font2.ttf';
  src: url('../../../assets/fonts/font2.ttf') format('truetype');
}
@font-face {
  font-family: 'font3.ttf';
  src: url('../../../assets/fonts/font3.ttf') format('truetype');
}
@font-face {
  font-family: 'font4.ttf';
  src: url('../../../assets/fonts/font4.ttf') format('truetype');
}
@font-face {
  font-family: 'font5.ttf';
  src: url('../../../assets/fonts/font5.ttf') format('truetype');
}
@font-face {
  font-family: 'font6.ttf';
  src: url('../../../assets/fonts/font6.ttf') format('truetype');
}
@font-face {
  font-family: 'font7.ttf';
  src: url('../../../assets/fonts/font7.ttf') format('truetype');
}
@font-face {
  font-family: 'font8.ttf';
  src: url('../../../assets/fonts/font8.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Regular.ttf';
  src: url('../../../assets/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Medium.ttf';
  src: url('../../../assets/fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Arimo-Bold.ttf';
  src: url('../../../assets/fonts/Arimo-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Arimo-Regular.ttf';
  src: url('../../../assets/fonts/Arimo-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Bold.ttf';
  src: url('../../../assets/fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Regular.ttf';
  src: url('../../../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Quicksand-Medium.ttf';
  src: url('../../../assets/fonts/Quicksand-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Quicksand-Regular.ttf';
  src: url('../../../assets/fonts/Quicksand-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway-Medium.ttf';
  src: url('../../../assets/fonts/Raleway-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway-Regular.ttf';
  src: url('../../../assets/fonts/Raleway-Regular.ttf') format('truetype');
}

.btn-red {
  background: #FF2F2F;
  border-color: #FF2F2F;
  max-height: 36px;
  width: 115px;
  color: white;
  font-size: 14px;
}
.btn-green {
  color: white;
  background: #36C626;
  border-color: #36C626;
  border-radius: 4px;
  min-width: 169px;
}
.btn-green:hover {
  color: white;
  background: #60d155;
  border-color: #60d155;
  border-radius: 4px;
  min-width: 169px;
}
.new-campaign {
  input {
    height: 36px;
    font-size: 14px;
    line-height: 22px;
  }
  hr {
    border-top: 1px solid #BFBFBF;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .ant-card {
    background: transparent;
  }
  .has-error {
    p.form-labels {
      color: #FF2F2F !important;
    }
    .ant-input {
      box-shadow: 0 0 3px rgb(245, 34, 45);
    }
  }
  .react-draggable {
    overflow: hidden;
  }
  .ql-editor {
    background: white;
    min-height: 300px;
  }
  .switch-green {
    background: #D7DBD7;
  }
  .switch-green.ant-switch-checked {
    background: #36C626;
  }
  .switch-green.ant-click-animating-node {
    background: 0 solid #36C626;
  }
  .element-container {
    border: 1px solid #bfbfbf;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
    border-radius: 7px;
    text-align: center;
    margin: 25px 0;
    padding: 20px;
    cursor: pointer;
    background: white;
    img {
      max-width: 100%;
      display: block;
      max-height: 150px;
      margin: 0 auto 10px auto;
    }
  }
  .element-container:hover {
    border: 1px solid #00a154 !important;
    background: rgb(207, 237, 223);
  }
  .poll-option {
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #36C626;
    border-radius: 7px;
    position: relative;
    .poll-option-remove {
      position: absolute;
      z-index: 9;
      top: 15px;
      right: 15px;
    }
  }
  .ant-form-item-control {
    margin: 16px 0;
    p.form-labels {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      color: #464646;
      .switch-green {
        margin-left: 16px;
        margin-top: -3px;
      }
    }
  }
  .ant-form-item-control.disabled {
    p.form-labels {
      color: #C7C7C7;
    }
  }
  .ant-card-body {
    padding: 6px;
  }
  .ant-tabs {
    overflow: unset;
  }
  .ant-tabs-bar {
    width: 20%;
    position: sticky;
    top: 30px;
    border-right: 1px solid #BFBFBF;
    .ant-card-body {
      padding-right: 0;
    }
    .ant-tabs-nav-container {
      min-height: calc(0.8 * (100vh - 166px));
      .ant-tabs-tab {
        text-align: left;
        padding: 22px 30px;
        color: rgba(29,29,29,0.65);
        font-size: 16px;
        margin-bottom: 0;
      }
      .ant-tabs-tab-active {
        font-weight: 600;
        color: #00a154 !important;
        border-right: 6px solid #00a154;
        background: #cfeddf;
      }
      .ant-tabs-ink-bar {
        width: 6px;
      }
    }
  }
  .ant-tabs-left-content {
    border-left: 1px solid #BFBFBF;
  }
  .ant-tabs-tab {
    border-right: 1px solid #BFBFBF;
  }
  .ant-tabs-content {
    // max-height: calc(0.8 * (100vh - 166px));
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 0;

    .tab-body {
      padding: 30px 60px;
    }
    .ant-tabs-tabpane {
      min-width: 678px;
    }
    // .campaign-tab {
    //   .tab-header {
    //     position: relative;
    //     padding-bottom: 15px;
    //     padding-left: 13px;
    //     border-bottom: 1px solid #979797;
    //     h3 {
    //       font-size: 20px;
    //       line-height: 22px;
    //       font-weight: 400;
    //       display: inline-block;
    //       margin: 0;
    //       padding-top: 5px;
    //     }
    //     .right {
    //       position: sticky;
    //       left: 100%;
    //     }
    //   }
    // }
  }
}
.upload-actions {
  margin-top: 0.71rem;
  display: flex;
  .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 3px;
    color: rgba(0,0,0,0.25);
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer;
    .anticon {
      font-size: 2.25rem;
    }
  }
}